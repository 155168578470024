<template>
  <div class="text-center" id="diag_text_root">
    <v-dialog
      overlay-color="white"
      overlay-opacity="1"
      id="mapdiag"
      v-model="map_dialog_visible"
      width="768"
    >
      <v-card
        height="820"
        v-show="print_ready == false"
        style="overflow-y: hidden"
      >
        <v-card-title class="text-h5 grey lighten-2">
          MAP of Reno
        </v-card-title>

        <v-card-text class="my-0 py-0"
          >Displays all locations of data sent to map.
        </v-card-text>
        <v-card-text class="my-0 py-0 pt-2" v-if="showsterms"
          ><h4>Search Terms</h4></v-card-text
        >
        <v-card-text class="my-0 py-0" v-if="showsterms"
          ><span class="" v-if="sterm.frm_date != '1/1/1900'"
            >From&nbsp;Date:{{ sterm.frm_date }}
            <span>To&nbsp;Date:{{ sterm.to_date }}</span>
          </span>
          <span class="" v-if="sterm.frm_date == '1/1/1900'"
            >From&nbsp;Date:{{ dates_boundry.frm_date }}
            <span>To&nbsp;Date:{{ dates_boundry.to_date }}</span>
          </span>

          <span class="ml-2" v-if="sterm.noterms">No Terms:TRUE</span>
          <span
            class="ml-2"
            v-if="sterm.stop_type != '' && sterm.stop_type != null"
            >Stop Type: {{ sterm.stop_type }}</span
          >
          <span class="ml-2" v-if="sterm.email != '' && sterm.email != null"
            >Created By: '{{ sterm.email }}'</span
          >
          <span
            class="ml-2"
            v-if="sterm.location != '' && sterm.location != null"
            >Location: '{{ sterm.location }}'</span
          >

          <span class="ml-4" v-if="sterm.accident">Accident: X </span>
          <span class="ml-4" v-if="sterm.arrest">Arrest: X </span>
          <span class="ml-4" v-if="sterm.cfs">CFS: X </span>
          <span class="ml-4" v-if="sterm.cites">Cites: X </span>
          <span class="ml-4" v-if="sterm.dui">DUI: X </span>
          <span class="ml-4" v-if="sterm.mait">MAIT: X </span>
          <span class="ml-4" v-if="sterm.sro">SRO: X </span>
          <span class="ml-4" v-if="sterm.stops">Stops: X </span>
          <span class="ml-4" v-if="sterm.deeds">Deeds: X </span>
        </v-card-text>
        <v-divider></v-divider>
        <div id="map-canvas" style="width: 750px; height: 600px"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="no-print" color="primary" @click="print_map">
            Print
          </v-btn>
          <v-btn class="no-print" color="primary" @click="close_mapdiag">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
      <div
        style="
          postion: absolute;
          top: 0;
          bottom: 0;
          width: 100vw;
          height: 100vh;
        "
        v-show="print_ready == true"
      >
        <h4 style="background-color: black; color: white">
          Traffic Stat Locations
        </h4>
        <div id="map-canvas-print" style="width: 100vw; height: 98vh"></div>
      </div>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
.vdialog,
.v-dialog {
  max-width: 768px;
}

/* print media */
@media only print {
  .box {
    width: 7.5in;
    // height: 10in;
  }

  .no-print {
    display: none;
  }
  .pgbreak-after {
    page-break-after: always;
  }
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "mapreport",
  mixins: [bus_common],
  props: ["map_dialog_visible", "lst_stat_data", "update_map", "sterm"],
  data() {
    return {
      showsterms: false,
      print_ready: false,
      lst_stats: [],
      lat: 0,
      lng: 0,
      map: null, // Global declaration of the map
      map_print: null,
      iw: null,
      marker: [],
      lat_longs: [],
      markers: [],
      drawingManager: null,
    };
  },
  computed: {
    dates_boundry() {
      return this.$store.state.dates_boundry;
    },
  },
  watch: {
    lst_stats: {
      handler(newValue) {
        if (this.lst_stats != null) {
          this.placeMarker();
        }
      },
      deep: true,
    },
    map_dialog_visible: function () {
      var self = this;
      if (this.map_dialog_visible == true) {
        this.$nextTick(() => {
          self.init();
        });
        // setTimeout(function () {
        //   self.init();
        // }, 3000);
      }
    },
    update_map: function () {
      //     this.placeMarker();
    },
  },
  methods: {
    print_map() {
      // this.print_ready = true;
      // this.map_initialize_print();
      window.print();
    },
    close_mapdiag() {
      var loc = {
        lat: this.lat,
        lng: this.lng,
      };
      this.$emit("close_mapdiag", loc);
      this.lst_stats = [];
    },
    placeMarker() {
      var self = this;
      if (this.markers.length != 0) {
        for (let index = 0; index < this.markers.length; index++) {
          const marker = this.markers[index];
          marker.setMap(null);
        }
        this.markers = [];
      }
      for (let index = 0; index < this.lst_stats.length; index++) {
        const stat = this.lst_stats[index];

        if (stat.lat != 0 || stat.lat != null) {
          var location = new google.maps.LatLng(stat.lat, stat.lon);
          var newMarker = new google.maps.Marker({
            position: location,
            map: self.map,
          });
          self.markers.push(newMarker);
          newMarker.setMap(self.map);
        }
      }
    },
    placeMarkerPrint() {
      var self = this;

      for (let index = 0; index < this.lst_stats.length; index++) {
        const stat = this.lst_stats[index];

        if (stat.lat != 0 || stat.lat != null) {
          var location = new google.maps.LatLng(stat.lat, stat.lon);
          var newMarker = new google.maps.Marker({
            position: location,
            map: self.map_print,
          });

          newMarker.setMap(self.map_print);
        }
      }
    },
    map_initialize_print() {
      var self = this;
      var bExist = false;
      let myLatlng = new google.maps.LatLng(39.5238581, -119.8515168); //at: 39.5238581, lng: -119.8515168

      let myOptions = {
        zoom: 12.5,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
      };
      this.map_print = new google.maps.Map(
        document.getElementById("map-canvas-print"),
        myOptions
      );
      this.placeMarkerPrint();
    },
    map_initialize() {
      var self = this;
      var bExist = false;
      let myLatlng = new google.maps.LatLng(39.5238581, -119.8515168); //at: 39.5238581, lng: -119.8515168

      let myOptions = {
        zoom: 12.5,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
      };
      this.map = new google.maps.Map(
        document.getElementById("map-canvas"),
        myOptions
      );

      setTimeout(function () {
        self.lst_stats = self.mno(self.lst_stat_data);
        self.showsterms = true;
      }, 500);
    },
    // overlayClickListener(overlay) {
    //   google.maps.event.addListener(overlay, "mouseup", (event) => {});
    // },
    init() {
      this.show_loader(false);
      var cur_size = window.matchMedia("(max-width: 600px)");
      if (cur_size.matches) {
        var width_hc = document.getElementById("home-container").offsetWidth;
        var height_hc = document.getElementsByTagName("BODY")[0].offsetHeight;
        document.getElementById("map-canvas").style.width =
          width_hc * 0.85 + "px";
        document.getElementById("map-canvas").style.height =
          height_hc * 0.5 + "px";
        document.querySelector(".v-card").style.height =
          height_hc * 0.75 + "px";

        let isIOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

        if (isIOS) {
          document.getElementById("map-canvas").style.height =
            height_hc * 0.48 + "px";
          document.querySelector(".v-card").style.height =
            height_hc * 0.75 + "px";
          console.log("ios: " + height_hc * 0.62 + "px");
        }
      }

      this.iw = new google.maps.InfoWindow(); // Global declaration of the infowindow
      this.map_initialize();
    },
  },
  mounted() {
    this.$nextTick(() => {
      //   this.init();
    });
  },
};
</script>
